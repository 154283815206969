import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../layouts/index'
import styles from './intercom.module.css'
import classNames from 'classnames'

import intercomLogo from '../../../assets/integrations/intercom.png'
import logo from '../../../assets/icon.png'

import shot1 from '../../../assets/intercom/shot1.png'
import shot2 from '../../../assets/intercom/shot2.png'
import shot3 from '../../../assets/intercom/shot3.png'

const appUrl =
  'https://www.intercom.com/app-store/?app_package_code=makerkit-cfq0&category=surveys-and-feedback'

const IntercomPage = () => (
  <Layout>
    <Helmet title={'Collect feedback with Intercom'}></Helmet>
    <div className="page">
      <div className={styles.hero}>
        <div className={styles.left}>
          <div className={styles.title}>
            Collect feedback, gain better insight, and build great products
            using <b>Intercom messenger.</b>
          </div>
          <div className={styles.cta}>
            <a href={appUrl} className="btn btn-success btn-lg">
              View in Intercom app store
            </a>
          </div>
          <div className={styles.help}>
            You'll need a Suggested account first. Get started with a{' '}
            <a href="https://app.suggested.co/register">free plan</a>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.inner}>
            <img alt="Intercom logo" src={intercomLogo} />
            <span className={styles.plus}>+</span>
            <img alt="Suggested logo" src={logo} />
          </div>
        </div>
      </div>

      <div className={styles.featuresContainer}>
        <div className={classNames(styles.feature, styles.reverse)}>
          <div className={styles.left}>
            <div className={styles.title}>
              Users can submit their feedback directly within Intercom's
              Messenger
            </div>
            <p>
              No need to login to a separate website, make it super easy for
              your customers and let them use a tool that they're already
              familiar with.
            </p>
          </div>
          <div className={styles.right}>
            <img alt="" className={styles.screenshot} src={shot1} />
          </div>
        </div>

        <div className={styles.feature}>
          <div className={styles.left}>
            <div className={styles.title}>
              Let your customers vote and comment on existing feedback
            </div>
            <p>
              Surface the most desired features your customers want, and use
              that to prioritise your product development.
            </p>
          </div>
          <div className={styles.right}>
            <img alt="" className={styles.screenshot} src={shot2} />
          </div>
        </div>

        <div className={classNames(styles.feature, styles.reverse)}>
          <div className={styles.left}>
            <div className={styles.title}>
              Create feedback on behalf of your customer
            </div>
            <p>
              Add feedback right there and then, when talking to a customer. No
              need to switch to a separate website to submit their feedback.
            </p>
          </div>
          <div className={styles.right}>
            <img alt="" className={styles.screenshot} src={shot3} />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IntercomPage
